.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.swiper-container {
  position: relative;
  overflow: hidden;
  width: 75vw; /* Adjusted to viewport width */
  height: 100%; /* Adjusted to viewport height */
  margin: auto;
  max-width: 591px; /* Maximum width */
  max-height: 350px; /* Maximum height */
}

.swiper-slide img {
  width: 100%; /* Full width of the slide */
  height: auto; /* Maintain aspect ratio */
  background-size: cover;
  background-position: center;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .swiper-container {
    width: 100vw; /* Use viewport width to ensure it fits the screen */
    height: auto; /* Adjust height as necessary */
    overflow: hidden; /* Prevent slides from enabling horizontal scrolling */
  }
}

.header-image {
  display: block;
  height: auto;
  width: 266px; /* Adjusted width */
  margin: 0 auto; /* Center the image */
  padding: 10px 0; /* 10px padding on top and bottom */
}

/* Styles from HTML page */
body {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  margin: auto;
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
}

.textContainer {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0; /* Added 10px padding on top and bottom */


}

@media only screen and (max-width: 600px) {
  .textContainer {
  width: 100%;
  max-width: 95vw;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 10px auto;
}
}

.textContainer > section {
  display: flex;
  flex-direction: column;
}

.textContainer > img {
  height: 50px;
  width: 50px;
  object-fit: fill;
  margin-right: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonStyling {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 4px 4px 4px 4px;
  color: #0fcc0f;
  background-color: rgb(147 252 147 / 56%);
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.text-style {
  font-size: 24px;
  margin: 0; /* Remove default margins */
}

#user-city {
  text-align: center; /* Center the user's city text */
}

@media only screen and (max-width: 600px) {
  .text-style {
    font-size: 16px;
  }
}

#customButton {
  width: 100%; /* Adjusted width */
  max-width: 350px;
  background-color: #00aeef;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  margin: 10px auto; /* Added margin for spacing and centering */
  animation: bounce 1s infinite alternate;
}

#customButton img {
  width: 1em;
  height: 1em;
  margin-left: 5px;
  margin-top: 5px;
}

.exclusiveContent {
  
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin-top: 20px; /* Add margin for spacing */
}

@media only screen and (max-width: 600px) {
  .exclusiveContent {
    width: 100%;
    max-width: 95vw;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 10px auto;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}